
import { defineComponent, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import _ from "lodash";
import draggable from "vuedraggable";
import helpers from "@/helpers/global";

export default defineComponent({
  name: "SetupAcceptableBehaviors",
  components: {
    draggable,
  },
  created() {
    this.$store.dispatch("user/loadCurrentUser");
    window.addEventListener("keydown", this.bindKeyboardShortcuts);
  },
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(error) {
      if (error) {
        ElMessage.error(error);
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 3000);
      }
    },
  },
  data() {
    return {
      step: "get_start", // get_start
      loaded: false,
      CUSTOM_FIELDS_ENABLED: true,
      CUSTOM_FIELDS_MINIMUM: 3,
      getStartedButtonLoading: false,
      canNextStepOrig: false,
      selectedExistsTemplate: "",
      scenarioIndexShowing: 1,
      acceptableBehaviorsScenario: [] as any[],
      drag: false,
      addAccepTableModalStatus: false,
      valueInputEN: "",
      valueInputTH: "",
      isLangEnEnabled: true as boolean,
      isLangThEnabled: false as boolean,
      publishSubmitStatus: true,
      customSurvey: [] as any,
      questionTypes: [
        {
          type: "open-ended",
          label: helpers.t("setup.customSurvey.Open_ended"),
          icon: "Edit_Pencil",
        },
        {
          type: "multiple-choice",
          label: helpers.t("setup.customSurvey.Multiple_Choice"),
          icon: "Radio",
        },
        {
          type: "multiple-select",
          label: helpers.t("setup.customSurvey.Multiple_Select"),
          icon: "Checkbox",
        },
      ] as any[],
      newChoice: "",
      title: "" as any,
      description: "" as any,
      dropdownDisabled: false,
      showWarningNextStep: false,
      addMultipleChoiceErrorMsg: "" as any,
      addMultipleSelectErrorMsg: "" as any,
    };
  },
  computed: {
    steps(): any[] {
      return [
        {
          step: "get_start",
          name: "Step 0",
          order: 1,
          title: "get start",
          visible: false,
        },
        {
          step: "setup_aceetable_behaivors_scenarios",
          name: "Step 1",
          order: 2,
          title: helpers.t("setup.customSurvey.Add_and_configure_questions"),
          desc: helpers.t(
            "setup.customSurvey.Select_from_different_question_and_content_blocks_to_create_an_easy_to_understand_survey"
          ),
        },
        {
          step: "Evaluating_Acceptable_Behaviors_Tutorial",
          name: "Step 2",
          order: 3,
          title: "Preview",
          desc: helpers.t(
            "setup.customSurvey.Review_your_questions_for_your_custom_survey"
          ),
          visible: false,
        },
        {
          step: "Acceptable_Behaviors_Setup_AllInOne",
          name: "Step 2",
          order: 4,
          title: helpers.t("setup.customSurvey.Preview"),
          desc: helpers.t(
            "setup.customSurvey.Review_your_questions_for_your_custom_survey"
          ),
        },
        {
          step: "setup_complete",
          name: "Step 4",
          order: 5,
          title: "setup_complete",
          visible: false,
        },
      ];
    },
    isFirstStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      return item.order == 1;
    },
    isLastStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order == this.steps.length;
    },
    canPreviousStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order > 1;
    },
    canNextStep(): boolean {
      if (this.step == "setup_aceetable_behaivors_scenarios") {
        // return !this.acceptableBehaviorsScenarioDisabled;

        return true;
      } else if (this.step == "Acceptable_Behaviors_Setup_AllInOne") {
        return this.canNextStepToFinish;
      }
      return true;
    },
    canNextStepToFinish(): boolean {
      return true;
    },
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["assessmentSection/error"];
    },
    loading() {
      return this.$store.getters["assessmentSection/loading"];
    },
    acceptableBehaviors() {
      return this.$store.getters["assessmentSection/acceptableBehaviors"];
    },
    selectScenario() {
      let value = true;
      const scenario = this.acceptableBehaviorsScenario;
      if (scenario[this.scenarioIndexShowing - 1].value) {
        value = false;
      }
      return value;
    },
    formId() {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values() {
      return this.$store.getters["assessmentTemplate/values"];
    },
    existsTemplates() {
      return this.$store.getters["assessmentTemplate/existsTemplates"];
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    submitNewScenarioStatusBTN() {
      if (this.isLangEnEnabled && !this.valueInputEN) return false;
      if (this.isLangThEnabled && !this.valueInputTH) return false;
      return true;
    },
    acceptableBehaviorsScenarioDisabled(): boolean {
      const items = this.acceptableBehaviorsScenario;
      return items.length < this.CUSTOM_FIELDS_MINIMUM;
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
  },
  async mounted() {
    const formId = this.$router.currentRoute.value.query.id;
    this.checkQuestionId();
    await this.load(formId);

    this.$store.dispatch("forceReady", null, { root: true });
  },
  unmounted() {
    window.removeEventListener("keydown", this.bindKeyboardShortcuts);
  },
  methods: {
    autoExpand(event: any) {
      // event.target.style.height = "auto";
      event.target.style.height = "28.8px";
      event.target.style.height = event.target.scrollHeight + "px";
    },
    iconUrl(name: string) {
      return require("@/assets/image/" + name + ".svg") || "";
    },
    // @deprecated
    addChoice(index: any, type: string) {
      let inputElement;
      let maxChoices = 0;
      if (type === "multiple-select") {
        inputElement = this.$refs.newChoiceInputMultiSelect as HTMLInputElement;
        maxChoices = 20;
      } else if (type === "multiple-choice") {
        inputElement = this.$refs.newChoiceInputMultiChoice as HTMLInputElement;
        maxChoices = 10;
      }
      if (!inputElement) return;
      const newChoiceValue = inputElement.value.trim();
      if (newChoiceValue !== "") {
        if (index >= 0 && index < this.customSurvey.length) {
          if (!this.customSurvey[index].choices) {
            this.customSurvey[index].choices = [];
          }
          if (this.customSurvey[index].choices.length >= maxChoices) {
            console.log(
              `Maximum number of choices (${maxChoices}) reached for ${type}.`
            );
            inputElement.disabled = true;
            inputElement.value = "Full Options";
            return;
          }
          this.customSurvey[index].choices.push({
            name: newChoiceValue,
            value: newChoiceValue,
            placeholder: "Add Option",
            isCorrect: false,
          });
          // const defaultChoices = ["Option 1", "Option 2"];
        }
      }
      inputElement.value = "";
    },
    addNewChoice(index: number) {
      // console.log(`addNewChoice ${index}`);
      // console.log(this.customSurvey, "customSurvey");
      if (!_.has(this.customSurvey, index)) {
        console.log("not found index");
        return false;
      }
      const item = _.get(this.customSurvey, index);
      if (!item) {
        console.log("not found item");
        return false;
      }
      const choices = _.get(item, "choices");
      if (!choices) {
        console.log("not found choices");
        return false;
      }
      const type = _.get(item, "type");
      if (type !== "multiple-choice" && type !== "multiple-select") {
        console.log("type is not multiple-choice or multiple-select");
        return false;
      }
      // console.log(type, "type");
      let maxChoices = 0;
      if (type === "multiple-select") {
        this.addMultipleSelectErrorMsg = "";
        maxChoices = 20;
      } else if (type === "multiple-choice") {
        this.addMultipleChoiceErrorMsg = "";
        maxChoices = 10;
      }
      // console.log(maxChoices, "maxChoices");
      if (choices.length > maxChoices) {
        console.log("Maximum number of choices reached for " + type + ".");
        if (type === "multiple-choice") {
          this.addMultipleChoiceErrorMsg = helpers.t(
            "setup.customSurvey.addMultipleChoiceErrorMsg"
          );
        } else if (type === "multiple-select") {
          this.addMultipleSelectErrorMsg = helpers.t(
            "setup.customSurvey.addMultipleSelectErrorMsg"
          );
        }
        return false;
      }
      this.customSurvey[index].choices.push({
        name: `Option ${choices.length + 1}`,
        value: "",
        placeholder: helpers.t("setup.customSurvey.Add_Option"),
        isCorrect: false,
      });
    },
    deleteChoice(indexQuestion: number, indexChoice: number) {
      if (indexQuestion >= 0 && indexQuestion < this.customSurvey.length) {
        const choices = this.customSurvey[indexQuestion].choices;

        // const valid = choices.length > 2;
        const valid = choices.length > 0;
        if (!valid) {
          console.log("Cannot delete, Options must be two or more.");
        } else {
          choices.splice(indexChoice, 1);

          let error = this.customSurvey[indexQuestion].choices.length < 2;
          console.log(error, "error");

          // @fixed quick to set easy error msg
          const type = this.customSurvey[indexQuestion].type;
          if (type === "multiple-choice") {
            this.addMultipleChoiceErrorMsg = "";
            if (error) {
              this.addMultipleChoiceErrorMsg = helpers.t(
                "setup.customSurvey.removeMultipleChoiceErrorMsg"
              );
            }
          } else if (type === "multiple-select") {
            this.addMultipleSelectErrorMsg = "";
            if (error) {
              this.addMultipleSelectErrorMsg = helpers.t(
                "setup.customSurvey.removeMultipleSelectErrorMsg"
              );
            }
          }
        }
      }
    },
    addToItemcustomSurvey(type: string) {
      if (this.customSurvey.length >= 20) {
        this.dropdownDisabled = true;
        return;
      }
      if (type === "open-ended") {
        this.customSurvey.push({
          type: type,
          question: "",
          description: "",
          answer: "",
        });
      } else {
        this.customSurvey.push({
          type: type,
          question: "",
          description: "",
          choices: [
            {
              name: "Option A",
              value: "",
              placeholder: (this as any).$i18n.t("setup.customSurvey.Option_A"),
              isCorrect: false,
              checked: false,
            },
            {
              name: "Option B",
              value: "",
              placeholder: (this as any).$i18n.t("setup.customSurvey.Option_B"),
              isCorrect: false,
              checked: false,
            },
          ],
          valid: false,
        });
      }
    },
    onClickSelectedType(type: string) {
      this.addToItemcustomSurvey(type);
    },
    toggleDropdown() {
      this.addAccepTableModalStatus = !this.addAccepTableModalStatus;
    },
    backToLastPage() {
      // console.log("backToLastPage()");
      // this.$router.push({ name: "Assessments" });
    },
    gotoPreviousStep() {
      if (!this.canPreviousStep) return false;

      if (this.step == "Evaluating_Acceptable_Behaviors_Tutorial") {
        return this.backToStart();
      } else if (this.step == "Acceptable_Behaviors_Setup_AllInOne") {
        return this.setupPreviousStep();
      }

      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      const order = item.order - 1;
      const previousItem = _.find(this.steps, { order: order });
      if (!previousItem) return false;

      this.step = previousItem.step;
    },
    checkStepCompletion() {
      // console.log("checkStepCompletion()");
      // let text =
      //   "Please fill in complete information before proceeding, such as Title of Section, Short Description, Questions.";
      if (this.customSurvey.length === 0) return false;
      for (const element of this.customSurvey) {
        if (!element.question || element.question.trim() === "") {
          // console.log("element", element.question);
          return false;
        }
        if (element.choices) {
          if (element.choices.length < 2) {
            return false;
          }
          for (const choice of element.choices) {
            const value = String(_.get(choice, "value", "")).trim();
            if (!value.length) {
              // console.log("element.choices", element.choices);
              return false;
            }
          }
        }
      }
      const titleContent = this.title.trim();
      const descriptionContent = this.description.trim();
      if (!titleContent || !descriptionContent) {
        return false;
      }
      return true;
    },
    async gotoNextStep() {
      if (this.isLastStep) return;

      if (!this.checkStepCompletion()) {
        this.showWarningNextStep = true;
        return;
      }
      this.showWarningNextStep = false;

      if (this.step === "setup_aceetable_behaivors_scenarios") {
        this.getTutorail();
        this.step = "Acceptable_Behaviors_Setup_AllInOne";
        return;
      } else if (this.step == "Acceptable_Behaviors_Setup_AllInOne") {
        return this.setupNexStep();
      }
    },
    activeClass() {
      return "active";
    },
    tabNextStepCls(step: string) {
      let cls = "";
      const item = _.find(this.steps, { step: step });
      if (item && item.step == this.step) {
        cls = "active";
      } else {
        const currentItem = _.find(this.steps, { step: this.step });
        if (currentItem && item) {
          if (currentItem.order > item.order) {
            cls = "passed";
          }
        }
      }

      return cls;
    },
    gotoStep($step: any) {
      return 1;
    },
    publishSubmit() {
      this.publishSubmitStatus = false;
      this.closeTab();
      this.publishSubmitStatus = true;
    },
    bindKeyboardShortcuts(event: KeyboardEvent) {
      if (this.step != "Acceptable_Behaviors_Setup") return;
      const choices = ["0", "1", "2", "3", "4"];
      if (_.indexOf(choices, event.key) === -1) return;
      const scenarios = this.acceptableBehaviors.scenarios;
      const key = parseInt(event.key);
      for (const i in scenarios) {
        const scenario = scenarios[i];
        if (scenario.order == this.scenarioIndexShowing) {
          const question = scenario.questions[0];
          const options = question.options;
          for (const j in options) {
            const option = options[j];
            const value = parseInt(option.value);
            if (value == key) {
              this.onClickOption(scenario, question, option);
              break;
            }
          }
        }
      }
    },
    async initCustomFields() {
      await this.loadAcceptableBehaviors();
      const isNew = !(this.values.acceptable_behaviors_is_custom || false);
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      const defaultQuestion = {
        id: 1,
        index: 1,
        label: "",
        labelTH: "",
        value: "",
      };
      let acceptableBehaviorsScenario = [];
      if (isNew) {
        for (const i in this.acceptableBehaviors.scenarios) {
          const path = "scenarios[" + i + "].questions[0]";
          const question = _.get(this.acceptableBehaviors, path);
          const order = this.acceptableBehaviors.scenarios[i].order;
          const uiQuestion = {};
          _.merge(uiQuestion, defaultQuestion, {
            id: question.id,
            index: order,
            label: this.getInputLabelValue(
              lang,
              question.title,
              question.title_th
            ),
            labelEN: question.title,
            labelTH: question.title_th,
          });
          acceptableBehaviorsScenario.push(uiQuestion);
        }
      } else {
        const customFields = this.values.acceptable_behaviors_custom_fields;
        if (customFields) {
          acceptableBehaviorsScenario = customFields;
        }
      }

      const doTesting = false;
      if (doTesting && acceptableBehaviorsScenario) {
        this.acceptableBehaviorsScenario = acceptableBehaviorsScenario.slice(
          0,
          this.CUSTOM_FIELDS_MINIMUM
        );
        return;
      }

      this.acceptableBehaviorsScenario = acceptableBehaviorsScenario;
    },
    applyCustomFields() {
      const defaultQuestion = {
        checked: false,
        dirty: false,
        id: "",
        name: "",
        options: [
          { label: "Acceptable", value: 0, label_th: "ยอมรับได้" },
          { label: " Frowned Upon", label_th: "ไม่พอใจ/ไม่ยอมรับ", value: 1 },
          {
            label_th: "แสดงออกว่าไม่สนับสนุน",
            label: "Actively Discouraged",
            value: 2,
          },
          {
            label: "Reprimanded",
            value: 3,
            label_th: "ต้องมีการตำหนิหรือลงโทษ",
          },
          {
            value: 4,
            label_th: "ยอมรับไม่ได้อย่างเด็ดขาด",
            label: " Zero Tolerance Policy",
          },
        ],
        order: 1,
        title: "",
        title_th: "",
        type: "choice",
        value: "",
      };
      let newScenarios = [];
      let order = 1;
      for (const i in this.acceptableBehaviorsScenario) {
        const uiQuestion = this.acceptableBehaviorsScenario[i];
        const targetQueston = { id: 1, order: 1 };
        _.merge(targetQueston, defaultQuestion, {
          id: uiQuestion.id,
          name: uiQuestion.label,
          order: order++,
          title: uiQuestion.label,
          title_th: uiQuestion.labelTH,
        });
        const scenario = {
          description:
            '<div class="">Please provide a rating for each scenario based on your preference and tolerance levels.</div>',
          description_th:
            '<div class="">' +
            helpers.t(
              "question.acceptableBehaviors.Please_provide_a_rating_for_each"
            ) +
            "</div>",
          id: targetQueston.id,
          name: "scenario_" + targetQueston.order,
          order: targetQueston.order,
          questions: [targetQueston],
          title: "Evaluating Acceptable Behaviors",
          title_th: helpers.t(
            "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
          ),
        };
        newScenarios.push(scenario);
      }
      newScenarios = _.sortBy(newScenarios, "order");

      this.acceptableBehaviors.scenarios = newScenarios;
      this.$store.commit(
        "assessmentSection/acceptableBehaviors",
        this.acceptableBehaviors,
        { root: true }
      );
    },
    initForms() {
      if (this.values && this.values.acceptable_behaviors) {
        const valuesMap: any = {};
        for (const i in this.values.acceptable_behaviors) {
          const item = this.values.acceptable_behaviors[i];
          if (item) {
            for (const qid in item) {
              valuesMap[qid] = item[qid];
            }
          }
        }

        if (this.acceptableBehaviors && this.acceptableBehaviors.scenarios) {
          for (const i in this.acceptableBehaviors.scenarios) {
            const scenario = this.acceptableBehaviors.scenarios[i];
            if (scenario && scenario.questions) {
              for (const j in scenario.questions) {
                const question = scenario.questions[j];
                if (question) {
                  const qid = question.id;
                  if (Object.prototype.hasOwnProperty.call(valuesMap, qid)) {
                    const value = valuesMap[qid];
                    if (Array.isArray(question.options)) {
                      const option = _.find(question.options, { value: value });
                      if (option) {
                        this.onClickOption(scenario, question, option);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    async getStarted() {
      if (this.getStartedButtonLoading) return;
      this.getStartedButtonLoading = true;
      window.scrollTo(0, 0);
      await this.useSelectedExistsTemplate();

      if (Array.isArray(this.values.languages_required)) {
        this.isLangEnEnabled =
          _.indexOf(this.values.languages_required, "English") !== -1;
        this.isLangThEnabled =
          _.indexOf(this.values.languages_required, "Thai") !== -1;
      }

      if (this.CUSTOM_FIELDS_ENABLED) {
        // await this.initCustomFields();
        await this.loadCustomSurvey();
        this.getStartedButtonLoading = false;
        this.step = "setup_aceetable_behaivors_scenarios";
      } else {
        this.getStartedButtonLoading = false;
        this.step = "Evaluating_Acceptable_Behaviors_Tutorial";
      }
    },
    backToStart() {
      if (this.CUSTOM_FIELDS_ENABLED) {
        this.step = "setup_aceetable_behaivors_scenarios";
      } else {
        this.step = "get_start";
      }
    },
    async getTutorail() {
      if (!this.acceptableBehaviorsScenarioDisabled) {
        this.applyCustomFields();
        this.initForms();
      }
    },
    updateCradList() {
      for (let i = 0; i < this.acceptableBehaviorsScenario.length; i++) {
        this.acceptableBehaviorsScenario[i].index = i + 1;
      }
    },
    getInputLabelValue(lang: string, labelEn: any, labelTh: any) {
      if (this.isLangEnEnabled && this.isLangThEnabled) {
        return lang == "en" ? labelEn : labelTh;
      } else {
        if (this.isLangEnEnabled) {
          return labelEn;
        } else if (this.isLangThEnabled) {
          return labelTh;
        }
      }
    },
    removeScenario(index: number) {
      ElMessageBox.confirm(
        (this as any).$t("popup.question.question_box_5.question"),
        (this as any).$t("popup.question.question_box_5.title"),
        {
          confirmButtonText: (this as any).$t("popup.optional.Confirm"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.customSurvey = this.customSurvey.filter(
          (item: any, i: number) => i !== index
        );
      });
    },
    async useSelectedExistsTemplate() {
      if (this.selectedExistsTemplate) {
        const selectedExistsTemplate = _.find(this.existsTemplates, {
          _id: this.selectedExistsTemplate,
        });
        if (selectedExistsTemplate) {
          this.getStartedButtonLoading = true;
          const formId = this.$router.currentRoute.value.query.id;
          await this.$store.dispatch(
            "assessmentTemplate/loadFromExistsTemplate",
            {
              formId: formId,
              selectedExistsTemplate: selectedExistsTemplate,
              section: "acceptable_behaviors",
            },
            {
              root: true,
            }
          );
          this.getStartedButtonLoading = false;
        }
      }
    },
    async loadAcceptableBehaviors() {
      if (!this.loaded) {
        await this.$store.dispatch(
          "assessmentSection/loadSetupAcceptableBehaviors",
          null,
          {
            root: true,
          }
        );
        if (this.acceptableBehaviors) {
          const hasOwnProperty = Object.prototype.hasOwnProperty;
          if (
            this.values &&
            hasOwnProperty.call(this.values, "acceptable_behaviors")
          ) {
            const history = this.values.acceptable_behaviors;
            try {
              for (const key in this.acceptableBehaviors.scenarios) {
                const scenario = this.acceptableBehaviors.scenarios[key];
                const scenarioKey = _.snakeCase(key);
                for (const i in scenario.questions) {
                  const question = scenario.questions[i];
                  const questionId = question.id;
                  const hasScenario = hasOwnProperty.call(history, scenarioKey);
                  if (hasScenario) {
                    const hasValue = hasOwnProperty.call(
                      history[scenarioKey],
                      questionId
                    );
                    if (hasValue) {
                      const value = history[scenarioKey][questionId];
                      const option = _.find(question.options, { value: value });
                      if (!option) {
                        const errorMsg = `Not found option value ${value} on scenario order ${scenario.order}`;
                        console.log(errorMsg);
                        continue;
                      }
                      this.onClickOption(scenario, question, option);
                    }
                  }
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
        }

        this.loaded = true;
      }
    },
    async loadCustomSurvey() {
      const values = this.$store.getters["assessmentTemplate/values"] || {};
      const fields = _.get(values, "custom_survey_custom_fields");
      if (fields) {
        this.customSurvey = fields;
      }
      this.title = _.get(values, "custom_survey_title", "");
      this.description = _.get(values, "custom_survey_description", "");
    },
    async load(formId: any) {
      console.log(`load(${formId})`);
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "custom_survey" },
        { root: true }
      );
    },
    onClickOption(scenario: any, question: any, option: any) {
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].id != scenario.id) continue;
        for (const j in scenarios[i].questions) {
          for (const k in scenarios[i].questions[j].options) {
            const o = scenarios[i].questions[j].options[k];
            o.checked = o.value == option.value;
            if (o.checked) {
              this.canNextStepOrig = true;
            }
          }
        }
      }
    },
    async finishSetup() {
      console.log("finishSetup()");
      this.publishSubmitStatus = true;
      this.$store.commit("assessmentSection/loading", true);

      let sectionAnswersDto: any = {
        _id: this.$router.currentRoute.value.query.id,
        section: {
          name: "custom_survey",
          // name: "customSurvey",
          scenarios: [{ questions: [{ id: "x" }] }],
        },
        answers: [{ id: "x", value: 1 }],
        callback: () => {
          this.$store.commit("assessmentSection/loading", false);
          this.step = "setup_complete";
        },
      };

      if (this.CUSTOM_FIELDS_ENABLED) {
        sectionAnswersDto.customSurveyIsCustom = true;
        sectionAnswersDto.customSurveyCustomFields = this.customSurvey;
        sectionAnswersDto.customSurveyTitle = this.title;
        sectionAnswersDto.customSurveyDescription = this.description;
      }

      // sectionAnswersDto.custom_survey_title = this.title;
      // sectionAnswersDto.custom_survey_description = this.description;

      this.$store.dispatch(
        "assessmentTemplate/saveSectionAnswers",
        sectionAnswersDto,
        {
          root: true,
        }
      );
      this.publishSubmitStatus = false;
    },
    applyStepperButtons() {
      let isChecked = false;
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].order != this.scenarioIndexShowing) continue;
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              isChecked = true;
            }
          }
        }
      }
      this.canNextStepOrig = isChecked;
    },
    checkQuestionId() {
      const id = this.$router.currentRoute.value.query.id;
      if (!id) {
        ElMessage.error((this as any).$t("popup.message.message_16e"));
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 1000);
      }
    },
    closeTab() {
      window && window.close();
    },
    setupPreviousStep() {
      const index = this.scenarioIndexShowing;
      if (index == 1) {
        this.step = "setup_aceetable_behaivors_scenarios";
      } else {
        this.scenarioIndexShowing = index - 1;
        this.applyStepperButtons();
      }
    },
    async setupNexStep() {
      if (!this.canNextStepToFinish) return;
      await this.finishSetup();
    },
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
    change(e: any, name: string) {
      _.set(this, name, e.target.textContent);
    },
    inputCls(name: string) {
      const value = String(_.get(this, name, ""));
      return `input-contenteditable ${value.length ? "has-value" : ""}`;
    },
  },
});
